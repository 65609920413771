define("discourse/plugins/discourse-github-verification/discourse/components/github-user-link", ["exports", "@ember/component", "@ember/template-factory"], function (_exports, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="github-verified-username">
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://github.com/{{this.username}}"
    >
      {{d-icon "fab-github"}}
      {{this.username}}
    </a>
  </div>
  */
  {
    "id": "UGi8yA+t",
    "block": "[[[10,0],[14,0,\"github-verified-username\"],[12],[1,\"\\n  \"],[10,3],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[15,6,[29,[\"https://github.com/\",[30,0,[\"username\"]]]]],[12],[1,\"\\n    \"],[1,[28,[35,2],[\"fab-github\"],null]],[1,\"\\n    \"],[1,[30,0,[\"username\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"div\",\"a\",\"d-icon\"]]",
    "moduleName": "discourse/plugins/discourse-github-verification/discourse/components/github-user-link.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, class extends _component.default {});
});